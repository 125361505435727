import {formOptionsPreset} from "@/shared/fields";
export const formOptionsGen = () => {
  return [
    [
      {
        name: '노출상태', key: 'displayStatus', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'view', variant: 'success'},
          {text: '미노출', value: 'notview', variant: 'warning'}
        ]
      },
      {name: '상품 특징', key: 'features', options: formOptionsPreset.EXISTS_YN},
      {name: '사이즈 변환', key: 'sizeTable', options: formOptionsPreset.EXISTS_YN},
      // {key: 'divider'},
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: '생성시각', value: '_cdt', variant: 'primary'},
          {text: '변경시각', value: '_mdt', variant: 'primary'},
          {text: '발란코드', value: 'goodsNo', variant: 'info'},
          {text: 'GM ID', value: 'gm_id', variant: 'info'},
          {text: '가격', value: 'price', variant: 'info'},
        ]
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
    ],
  ];
};

export const formFieldsGen = () => {
  return [
    {group: '상품속성'},
    {name: '발란코드', key: 'goodsNo', type: 'number'},
    {name: '상품명', key: 'goodsNm', type: 'string', width: 180},
    {name: 'Sku ID', key: 'skuId', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 150},
    {name: '원산지', key: 'origin', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 150},
    {name: '소재 태그', key: 'compositionTag', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 120},
    {name: '소재', key: 'composition', type: 'string', enableExists: true, disableRange: true, width: 120},
    {name: '컬러', key: 'color', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 130},

    {group: '재고, 가격, 옵션'},
    {
      name: '발란회원가', key: 'price', type: 'number', rangePreset: [
        {text: '~ 1만원', gte: '', lte: 10000},
        {text: '1 ~ 3만원', gte: 10000, lte: 30000},
        {text: '3 ~ 10만원', gte: 30000, lte: 100000},
        {text: '10 ~ 20만원', gte: 100000, lte: 200000},
        {text: '20 ~ 30만원', gte: 200000, lte: 300000},
        {text: '30 ~ 50만원', gte: 300000, lte: 500000},
        {text: '50 ~ 100만원', gte: 500000, lte: 1000000},
        {text: '100 ~ 300만원', gte: 1000000, lte: 3000000},
        {text: '300 ~ 500만원', gte: 3000000, lte: 5000000},
        {text: '500 ~ 1000만원', gte: 5000000, lte: 10000000},
        {text: '1000만원 ~', gte: 10000000, lte: ''},
      ]
    },
    {name: 'USD', key: 'usdPrice', type: 'number'},
  ];
};

export const formIncExcGen = () => {
  return [
    {name: '발란코드', key: 'goodsNo', type: 'number'},
    {name: 'GM ID', key: 'gm_id', type: 'string'},
    {name: 'Sku ID', key: 'skuId', type: 'string'},
    {name: '브랜드 코드', key: 'brand_no', type: 'number'},
    {name: '최종카테고리 코드', key: 'category', type: 'string'},
    {name: '원산지', key: 'origin', type: 'string'},
    {name: '컬러', key: 'color', type: 'string'},
  ];
}
