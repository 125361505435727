<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="상품관리">
        <shopify-goods></shopify-goods>
      </b-tab>
      <b-tab title="가격관리">
        <shopify-pricing v-if="loaded[1]"></shopify-pricing>
      </b-tab>
      <b-tab title="API Log">
        <shopify-api-logs v-if="loaded[2]"></shopify-api-logs>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ShopifyGoods from './ShopifyGoods.vue'
import ShopifyPricing from './ShopifyPricing.vue'
import ShopifyApiLogs from './ShopifyApiLogs.vue'

export default {
  name: 'Shopify',
  title: 'Shopify 관리',
  components: {ShopifyGoods, ShopifyPricing, ShopifyApiLogs},
  data() {
    return {
      tabIndex: 0,
      loaded: {}, // {[tabIndex]: true} - lazy loading
    }
  },
  watch: {
    tabIndex(v) {
      this.loaded[v] = true;
    },
  },
}
</script>
