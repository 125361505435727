<template>
  <div>
    <b-btn class="mb-1" size="sm" @click="list" :disabled="busy.list">
      <i class="fa fa-refresh"></i>
      <b-spinner v-if="busy.list" small class="align-middle"></b-spinner>
    </b-btn>
    <b-row>
      <b-col cols="12" lg="8">
        <htb ref="htb" v-model="items.list" :config="htbConfig" height="calc(100vh - 300px)"></htb>
      </b-col>
      <b-col cols="12" lg="4">
        <htb ref="htb2" v-model="items.list2" :config="htbConfig" height="calc(100vh - 300px)"></htb>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>

<script>

export default {
  name: 'ShopifyApiLogs',
  title: 'Shopify API 로그 기록',
  data() {
    return {
      lastBody: {list: {}, list2: {}},
      items: {list: [], list2: []},
      busy: {list: false, listmore: false, list2: false, list2more: false},
      hasMore: {list: false, list2: false},
      ac: {list: null, list2: null}, // abortController

      htbConfig: {
        settings: {
          contextMenu: true,
          filters: true,
          dropdownMenu: true,
        }
      }
    }
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      await this.$api.postTable(this, '/goods/shopify/apiLog', {}, {fnAssign: this.assignTableData});
      await this.$api.postTable(this, '/goods/shopify/unprocessedCountLog', {}, {key: 'list2'});
      this.render();
    },
    assignTableData(e) {
      Object.entries(e).forEach(([k, v]) => {
        if (typeof v === 'object') {
          e[k] = JSON.stringify(v, null, 2);
        }
      });
    },
    render() {
      setTimeout(() => this.$refs.htb.hotInstance.render(), 0);
      setTimeout(() => this.$refs.htb2.hotInstance.render(), 0);
    },
  }
}
</script>
